@import "@/assets/scss/var.scss";















































.hv-cube {
    min-height: 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
    &-item {
        vertical-align: top;
        .hv-image {
            height: 100%;
            /deep/ .el-image {
                vertical-align: top;
                width: 100%;
                height: 100%;

                img {
                    object-fit: cover;
                }
            }
        }
    }
}
// .hv-cube.two{
//     height: 130px;
// }
// .hv-cube.three{
//     height: 90px;
// }
// .hv-cube.four{
//     height: 65px;
// }
.hv-cube.two,
.hv-cube.three,
.hv-cube.four {
    display: flex;
    flex-flow: wrap;
}
.hv-cube.two {
    // height: 130px;
    .hv-cube-item {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
    }
}
.hv-cube.three {
    // height: 90px;
    .hv-cube-item {
        display: inline-block;
        width: 33.33%;
        box-sizing: border-box;
    }
}
.hv-cube.four {
    // height: 65px;
    .hv-cube-item {
        display: inline-block;
        width: 25%;
        box-sizing: border-box;
    }
}
.hv-cube.cube {
    // height: 300px;
    .hv-cube-item {
        display: inline-block;
        width: 50%;
        height: 50%;
        box-sizing: border-box;
    }
}
// .hv-cube.hybrid1{
//     height: 300px;
//     display: flex;
//     .hv-cube-item{
//         display: inline-block;
//         width: 50%;
//         height: 50%;
//         box-sizing: border-box;
//     }
//     .hv-cube-item:first-child{
//         width: 50%;
//         height: 100%;
//     }
//     .hv-cube-item:last-child{
//         position: absolute;
//         right: 0;
//         bottom: 0;
//     }
// }
.hv-cube.hybrid1 {
    // height: 300px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    .hv-cube-item:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
    }
}
.hv-cube.hybrid2 {
    // height: 300px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    .hv-cube-item:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
}
.hv-cube.hybrid3 {
    // height: 300px;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(2, 50%);
    .hv-cube-item:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .hv-cube-item:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
    }
}
