@import "@/assets/scss/var.scss";





















































































































































































.header-search {
    // width: auto;

    .search-head {
        display: flex;
        align-items: center;
        border: 0.5px #ccc solid;
        .el-input__inner {
            border: none;
            margin-top: 1px;
            margin-bottom: 1px;
        }
        .el-input-group__prepend {
            background-color: #fff;
        }
    }
    .el-select .el-input {
        width: 120px;

        margin-left: 1px;
    }

    > .el-input {
        // width: 100%;
        width: 265px;
        flex: 1;
        /deep/ .el-input-group__append {
            cursor: pointer;
        }
    }
    .serach-btn {
        margin-right: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-left: 5px;
        // background-color: #ff6b00;
        // border-color: #ff6b00;
    }
    .serach-btn:active,
    .serach-btn:focus,
    .serach-btn:hover {
        // background: #ff6b00;
        // border-color: #ff6b00;
    }
}
.search-popover {
    &-info {
        max-height: 300px;
        overflow-y: auto;
    }
    .el-divider--horizontal {
        margin: 10px 0;
    }
    .search-his {
        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-icon-delete {
                cursor: pointer;
            }
        }
        &-list {
            display: flex;
            flex-flow: wrap;
            .his-item {
                // height: 30px;
                // line-height: 30px;
                cursor: pointer;
                margin-right: 8px;
            }
            .his-item:hover {
                color: #ef0f25;
            }
        }
    }
    .search-tags {
        display: flex;
        flex-flow: wrap;
        // height: 35px;
        // line-height: 40px;
        padding: 5px 0;
        width: 100%;
        font-size: 14px;
        .tag-item {
            margin-right: 8px;
            cursor: pointer;
            color: #909399;
        }
        .tag-item:hover {
            color: #ef0f25;
        }
    }
}
