@import "@/assets/scss/var.scss";
.goods-item {
    text-align: right;
    header {
        margin-bottom: $offset-v;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
            flex: 1;
        }
        .header-commodity {
            text-align: left;
            flex: 2;
        }
    }
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $offset-v;
        flex-flow: wrap;
        > div {
            flex: 1;
        }
        .item__left {
            display: flex;
            align-items: center;
            position: relative;
            text-align: left;
            flex: 2;
            &-checkbox {
                margin-right: $offset-h;
                /deep/ .el-checkbox {
                    &__inner {
                        width: 20px;
                        height: 20px;
                    }
                    &__inner::after {
                        height: 9px;
                        left: 6px;
                        top: 3px;
                        width: 5px;
                    }
                }
            }
            &-img {
                width: 80px;
                height: 80px;
                min-width: 80px;
            }
            &-tag {
            }
            &-name {
                margin-left: $offset-h;
            }
            &-option {
                font-size: 14px;
                color: $txt-color-grey;
            }
        }

        .item__price {
            &-origin {
                font-style: italic;
                color: $txt-color-grey;
                margin-right: $offset-h-sm;
                text-decoration: line-through;
            }
            &-sell {
                color: $color-error;
            }
        }
        &--border {
            padding: $offset-base-md;
            // margin-top: $offset-v;
            border: 1px solid #e9e9e9;
            .item__left {
                &-img {
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                }
            }
        }
    }
    &-item:last-child {
        margin-bottom: 0;
    }
}
