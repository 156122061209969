@import "@/assets/scss/var.scss";
.inv-form {
	.tips {
		line-height: initial;
		color: $txt-color-grey;
	}
	.el-autocomplete {
		width: 100%;
	}
	
	
	.el-autocomplete-suggestion li .code {
		font-size: 12px;
		color: #b4b4b4;
	}
}
// .el-autocomplete-suggestion .love-autocomplete li {
// 	line-height: normal !important;
// 	padding-top: 7px;
// 	padding-bottom: 7px;
// }