@import "@/assets/scss/var.scss";


















































































































@import "../../../assets/scss/components.scss";

.hv-coupon {
    min-height: 20px;
    position: relative;
    // width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    // background: salmon;
    // padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    flex-flow: wrap;
    display: flex;
    &-item {
        color: #fff;
        display: flex;
        align-items: center;
        // width: 100%;
        height: $coupon-height + px;
        position: relative;
        margin-bottom: 10px;
        background: $coupon-color;
        flex-basis: calc(33.33% - 20px);
        margin: 10px;
    }
    &__left,
    &__right {
        display: flex;
        align-items: center;
    }
    &__left {
        // flex: 1;
        width: 80%;
        padding: 0 10px;
    }
    &__right {
        width: 30%;
        cursor: pointer;
        // color: #fff;
        display: flex;
        justify-content: center;
    }
    // &__right::after{
    //     content: '';
    //     position: absolute;
    //     height: 100%;
    //     width: 3px;
    //     top: 0;
    //     right: -3px;
    //     background-image: linear-gradient(to bottom, $coupon-color 3px, transparent 3px, transparent), radial-gradient(6px circle at 3px 6px, transparent 3px, $coupon-color 3px);
    //     background-size: 3px 9px;
    // }
    &__writing {
        @extend .van-ellipsis;
    }
    &__value {
        display: flex;
        align-items: center;
        height: 30px;
        // margin-left: 20px;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        min-width: 38%;
        // min-width: 30%;
        /* text-align: center; */
        justify-content: center;
    }
    &__unit {
        position: relative;
        top: 4px;
        left: 4px;
        font-size: 12px;
    }
    &__desc {
        display: flex;
        flex-flow: column;
        width: 62%;
        padding: 0 5px;
    }
    &__name {
        @extend .van-ellipsis;
    }
    &__condition,
    &__time {
        font-size: 12px;
        opacity: 0.8;
        @extend .van-multi-ellipsis--l2;
    }
}
.hv-coupon.scroll {
    overflow: auto;
    flex-wrap: nowrap;
    display: flex;
    .hv-coupon {
        &-item {
            width: auto;
            min-width: 80%;
            margin-right: 10px;
        }
    }
}

.hv-coupon.style2 {
    .hv-coupon {
        &-item {
            background: radial-gradient(circle at left top, transparent 5px, $coupon-color 0) top right / 20% 51% no-repeat,
                radial-gradient(circle at left bottom, transparent 5px, $coupon-color 0) bottom right / 20% 51% no-repeat,
                radial-gradient(circle at right top, transparent 5px, $coupon-color 0) top left / 80% 51% no-repeat,
                radial-gradient(circle at right bottom, transparent 5px, $coupon-color 0) bottom left / 80% 51% no-repeat;
            filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
        }
        &-item::before {
            content: "";
            height: ($coupon-height - 14) + px;
            border-left: 1px dashed #fff;
            position: absolute;
            right: 20%;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &-item::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 3px;
            top: 0;
            left: -3px;
            background-image: linear-gradient(to bottom, $coupon-color 3px, transparent 3px, transparent), radial-gradient(6px circle at 0px 6px, transparent 3px, $coupon-color 3px);
            background-size: 3px 9px;
        }
    }
}
.hv-coupon.style3 {
    .hv-coupon {
        &-item {
            background: unset;
        }
        &__left {
            margin-right: 1px;
        }
        &__left,
        &__right {
            border-radius: 5px;
            height: 100%;
            background: $coupon-color;
        }
    }
}
.hv-coupon.style4 {
    .hv-coupon {
        &-item {
            background: $coupon-color2;
            color: $coupon-color;
            border: 2px solid;
        }
        &-item::before {
            content: "";
            height: ($coupon-height / 2) + px;
            border-left: 1px dashed #fdc5ba;
            position: absolute;
            right: 20%;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &-item:after {
            position: absolute;
            top: 3px;
            left: 3px;
            box-sizing: border-box;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            margin: 0;
            border: 1px solid $coupon-color;
            content: " ";
        }
    }
}
.hv-coupon.style5 {
    .hv-coupon {
        &-item {
            padding: 5px;
            border-radius: 5px;
        }
        &__left {
            color: $coupon-color;
            background: #fff;
            height: 100%;
        }
        &__right {
            height: 100%;
            width: 20%;
            position: relative;
            // background: radial-gradient(circle at 100% 50%, transparent 7px, $coupon-color 0) top left/100% 100% no-repeat;
        }
    }
}
.hv-coupon.style6 {
    .hv-coupon {
        &-item {
            background: radial-gradient(circle at left top, transparent 5px, $coupon-color 0) top right / 20% 51% no-repeat,
                radial-gradient(circle at left bottom, transparent 5px, $coupon-color 0) bottom right / 20% 51% no-repeat,
                radial-gradient(circle at right top, transparent 5px, $coupon-color 0) top left / 80% 51% no-repeat,
                radial-gradient(circle at right bottom, transparent 5px, $coupon-color 0) bottom left / 80% 51% no-repeat;
            filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
            border-radius: 5px;
        }
        &-item::before {
            content: "";
            height: ($coupon-height - 14) + px;
            border-left: 1px dashed #fff;
            position: absolute;
            right: 20%;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}
