@import "@/assets/scss/var.scss";
.sw-simple-share{
    display: flex;
    align-items: center;
    font-size: $fontsize-sm;
    &__icon{
        display: flex;
        cursor: pointer;
        position: relative;
        width: 28px;
        height: 28px;
        text-align: center;
        justify-content: center;
        align-items: center;
        >svg{
            width: 28px;
            height: 28px;
            z-index: 1;
        }
        &--copy{
           >svg{
                width: 22px;
                height: 22px;
                margin-left: -2px;
           }
        }
    }
    &__icon + &__icon{
        margin-left: 8px;
    }
    &__icon:not(.sw-simple-share__icon--copy)::before {
        content: "";
        width: 70%;
        height: 70%;
        position: absolute;
        border-radius: 100%;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &__icon--copy{
        background: #8C95A0;
        fill: currentColor;
        border-radius: 100%;
        color: #fff;
    }
}
