@import "@/assets/scss/var.scss";













































































































.hv-video {
    width: 100%;
    height: 100%;
    // height: 100%;
    // height: 250px;
    /deep/ .video-player {
        height: 100%;
        width: 100%;
        .video-js {
            // width: 100% !important;
            // height: 100% !important;
            margin: 0 auto;
            .vjs-big-play-button {
                top: 50%;
                left: 50%;
                margin-left: -1.5em;
                margin-top: -1em;
                background-color: rgba(0, 0, 0, 0.45);
                font-size: 3em;
                height: 2em !important;
                line-height: 2em !important;
                margin-top: -1em !important;
            }
        }
        .video-js .vjs-tech {
            // position: static;
        }
    }
    /deep/ .youtube-media {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        iframe {
            width: 100% !important;
            height: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
