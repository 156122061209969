@import "@/assets/scss/var.scss";

































.hv-vtour {
	width: 100%;
	height: 100%;
	.vtour-content {
		cursor: pointer;
	}
	.vtour-content::before {
		content: "";
		display: block;
		width: 60px;
		height: 40px;
		background: rgba(0, 0, 0, 0.75);
		border-radius: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
	}
	.vtour-content::after {
		content: "";
		display: block;
		border-style: solid;
		border-width: 10px;
		border-color: transparent transparent transparent #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-15%, -50%);
	}
}
