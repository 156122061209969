@import "@/assets/scss/var.scss";






































































































































































































.headerWrapper {
  // position: fixed;
  width: 100%;
  // left: 0;
  // top: 0;
  // z-index: 1500;
  // height: 60px;
  // padding: 5px 0;
  // background: #f53d2d;
  // background: linear-gradient(-180deg, #f53d2d, #f63);
  // transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  color: #fff;
  .logo {
    // width: auto;
    // max-height: 60px;
    max-width: 25%;
    cursor: pointer;
  }
  .header {
    &-top {
      height: 35px;
      background: transparent;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu {
        cursor: pointer;
        &-items {
          .el-avatar {
            // margin-right: 5px;
            margin: 5px;
          }
          display: flex;
          align-items: center;
        }
        .user {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  > .header {
    // height: 60px;
    // background-color: #fff;

    // color: #fff;
    // top: 0;
    // left: 0;
    width: 100%;
    // line-height: 60px;
    // z-index: 100;
    // position: relative;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 100%;
      height: 85px;
      .serach-com {
        display: flex;
        align-items: center;
        background: #fff;
        flex: 1;
        margin: 0 20px;
        .header-search {
          width: 100%;
        }
      }
      .handle {
        order: 2;
        display: flex;
      }
      .cart-com {
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        .cart-i {
          font-size: 30px;
          color: #fff;
        }
      }

      .menu-com {
        position: relative;
        cursor: pointer;
        display: none;
        .menu-i {
          font-size: 30px;
          color: #fff;
        }
      }
    }
  }
}
