@import "@/assets/scss/var.scss";

























































































.hv-search {
	margin-right: 10px;
	width: 350px;
	.search-head {
		display: flex;
		align-items: center;
	}

	.el-input {
		// width: 100%;
		width: 265px;
		flex: 1;
		/deep/ .el-input-group__append {
			cursor: pointer;
		}
	}
}
.search-popover {
	&-info {
		max-height: 300px;
		overflow-y: auto;
	}
	.el-divider--horizontal {
		margin: 10px 0;
	}
	.search-his {
		&-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.el-icon-delete {
				cursor: pointer;
			}
		}
		&-list {
			display: flex;
			flex-flow: wrap;
			.his-item {
				// height: 30px;
				// line-height: 30px;
				cursor: pointer;
				margin-right: 8px;
			}
			.his-item:hover {
				color: #ef0f25;
			}
		}
	}
	.search-tags {
		display: flex;
		flex-flow: wrap;
		// height: 35px;
		// line-height: 40px;
		padding: 5px 0;
		width: 100%;
		font-size: 14px;
		.tag-item {
			margin-right: 8px;
			cursor: pointer;
			color: #909399;
		}
		.tag-item:hover {
			color: #ef0f25;
		}
	}
}
