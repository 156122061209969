@import "@/assets/scss/var.scss";
body #app {
    @media (max-width: $desktop) {
        .page-container {
            width: auto;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @media (max-width: $tablet-max) {
        .header-search .el-select .el-input {
            width: auto;
        }
    }

    @media (max-width: $tablet) {
        .nav-bar {
            display: none;
        }
        .menu-com {
            display: block;
        }

        .navmenu-collapse {
            color: #333;
            .el-drawer__body {
                .nav-bar {
                    display: block;
                    .page-container {
                        padding: 0;
                    }
                    .el-menu {
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        border-right: none;
                    }
                }
            }
        }
        .header-search .el-select .el-input {
            width: auto;
        }
        .headerWrapper .logo {
            // max-width: none;
            // max-height: 60px;
            // max-width: 35%;
            max-height: 60px;
            max-width: 150px;
        }
        .headerWrapper > .header .container > div {
            margin-bottom: 5px;
        }
        .headerWrapper > .header .container {
            flex-flow: wrap;
            position: relative;
            height: auto;
            //方案一
            // .logo{
            //     position: absolute;
            //     left: 0;
            // }
            // .serach-com{
            //     position: absolute;
            //     bottom: 0;
            //     margin: 0;
            //     margin-bottom: 10px;
            // }
            // .cart-com{
            //     position: absolute;
            //     right: 0;
            //     top: 20px;
            // }

            .logo {
                order: 1;
            }
            .serach-com {
                order: 3;
                flex-basis: 100%;
                margin: 0;
                margin-bottom: 10px;
            }
            // .cart-com{
            //     order: 2;
            // }
        }
    }

}

//#app容器外的样式 特殊处理
body {
    @media (max-width: $tablet-max) {
        .search-popover {
            width: 414px !important;
        }
    }
    @media (max-width: $tablet) {
        .search-popover {
            width: auto !important;
        }
    }
}



// header-nav 头部导览
.navmenu {
	&-btn {
		span {
			text-align: left;
			text-indent: -9999px;
			overflow: hidden;
			display: block;
			border-top: 2px solid currentColor;
			border-bottom: 2px solid currentColor;
			position: relative;
			width: 20px;
			height: 15px;
			margin: auto;
			box-sizing: border-box;

			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background: currentColor;
				position: absolute;
				top: 50%;
				margin-top: -1px;
			}
		}

		@media (min-width: $tablet) {
			display: none !important;
		}
	}
}

.hv-header {
	.navmenu-btn {
		padding: 0;
		width: 40px;
		height: 40px;
		margin: 5px 0;
	}

	.el-drawer__body {
		overflow: auto;
	}

	.el-drawer__header {
		margin-bottom: 0;
	}
}

.hv-navmenu {
	@media (max-width: $tablet-max) {
		&.el-menu--horizontal {
			display: none !important;
		}
	}
}
@media (max-width: $tablet-max) {
	.hv-header > .content {
		display: inherit;
	}
	.content-right {
		flex-flow: unset;
		.hv-header-nav {
			position: absolute;
			top: 10px;
			right: 10px;
			.navmenu-btn {
				margin: 0;
			}
		}
	}
	.hv-search {
		width: auto !important;
		position: absolute;
		right: 45px;
		top: 0px;
		.el-input {
			width: 100% !important;
		}
	}
}
@media (max-width: $mobile) {
	.hv-search {
		position: unset;
	}
}

// 商品页轮播图
.goods .hv-swiper .my-swipe .swiper-item {
	box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.3);
}

.goods .goods-content-right .btnGrp-container.is-active {
	@media (max-width: $tablet-max) {
		box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
	}
}

.Coupon {
	@media (max-width: $tablet-max) {
		.el-dialog {
			width: auto !important;
			max-width: 400px;
		}

		.inbox {
			display: block;
			width: auto;
			margin-right: 0;
		}

		&-item .l {
			width: auto;
			flex: 1;
		}

		.el-checkbox-group .inbox {
			width: 100%;
		}
	}
}

.checkout {
	@media (max-width: $tablet-max) {
		.row.coupon .row-label {
			min-width: unset;
			width: 30%;
		}
	}

	@media (max-width: $mobile) {
		.row .goods-item header {
			display: none;
		}

		.goods-item {
			.row {
				display: block;
				position: relative;

				&::after {
					content: "";
					display: block;
					clear: both;
				}

				.item__left {
					display: block;

					.el-image {
						float: left;
						margin-right: 10px;
					}

					&-name {
						margin-bottom: 24px;
					}
				}

				.item__price {
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}
		}

		.row.coupon {
			.row-label {
				width: auto;
				margin-right: auto;

				+ div {
					position: relative;

					span:not(:last-child) {
						padding-right: 15px;
					}

					.opendisc {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						text-indent: -9999px;
						font-size: 0;
						color: rgba(0, 0, 0, 0);
						text-align: left;
						overflow: hidden;
						margin-left: 0;

						&::before {
							content: "";
							display: block;
							width: 7px;
							height: 7px;
							border-style: solid;
							border-width: 1px;
							border-color: #606266 #606266 transparent transparent;
							top: 50%;
							transform: translateY(-50%) rotate(45deg);
							position: absolute;
							right: 5px;
						}
					}
				}
			}
		}

		.row.flexrow {
			flex-wrap: wrap;

			> div,
			.row-form {
				width: 100%;
				margin: 0;
			}
		}
	}
}

// 支付页
.pay {
	@media (max-width: $tablet-max) {
		&.main .section .status {
			display: block;
			padding: 0;

			> span .icon {
				margin: 0;
			}

			.status-msg {
				margin-top: 0;
				text-align: center;
			}
		}

		.section .status {
			.center {
				flex: 1;
			}

			> span {
				display: block;
				text-align: center;

				.icon {
					margin: 0;
				}
			}
		}

		.status .view-more {
			margin-top: $offset-v;
			text-align: right;
		}

		.payway .option-btn .el-radio {
			width: calc(50% - 10px);
		}
	}

	@media (max-width: $mobile) {
		.payway .option-btn {
			padding-bottom: 0;

			.el-radio {
				width: calc(100% - 10px);
			}
		}

		.section .status .order-detail li {
			display: flex;
			// flex-wrap: wrap;
			align-items: center;
		}

		.section {
			padding: 15px;

			.status {
				> span .icon {
					width: 5em;
					height: 5em;
				}

				.order-detail li > div {
					display: block;

					&.hv-label {
						width: auto;
						margin-right: auto;
						white-space: nowrap;

						+ div {
							text-align: right;
						}
					}
				}
			}
		}
	}
}

// 订单明细
.detail {
	@media (max-width: $tablet-max) {
		.detail-title ~ .detail-block {
			display: block;
		}
		.detail-block {
			.detail-block__state {
				display: block;
				text-align: right;
				&-desc {
					text-align: left;
					margin: $offset-v 0;
				}
			}
			&-col {
				&__info li:first-child {
					margin-bottom: $offset-v;
				}
				+ .detail-block-col {
					border: none;
					padding-left: 0;
					padding-right: 0;
					margin-top: $offset-v-lg;
				}
			}
		}
		.sw-check-out-list {
			margin-left: 0;
		}
	}
}

// 订单列表
.menu.main {
	@media (max-width: $tablet-max) {
		// display: flex;
		.page-content {
			// flex: 1;
			flex-direction: column;
			padding-left: 0;
			padding-right: 0;
			div.sidemenu {
				width: auto;
				margin-bottom: $offset-v;
				.el-menu-item * {
					vertical-align: top;
				}
				> .el-menu {
					padding: 0px;
				}
			}
			div.section {
				margin-left: 0;
				padding: 20px;
				.pg-title {
					display: none;
				}
				.profile .pg-title {
					display: block;
				}
			}
		}
		.sidemenu {
			width: auto;
			.title {
				display: none;
			}

			.el-menu-item {
				display: inline-block;
			}
		}

		.el-tabs {
			&__nav {
				&-next,
				&-prev {
					width: 20px;
					display: block;
					line-height: 40px;
				}
			}
			.header {
				font-size: 12px;
				span:first-child {
					display: none;
				}
			}
		}
	}
	@media (max-width: $mobile) {
		.header {
			// display: flex;
			// justify-content: space-between;
		}
		.el-tabs {
			.item {
				position: relative;
			}
		}
		.orders-goods {
			.amt {
				text-align: left;
			}
		}
		.orders-btns {
			text-align: right;
			position: absolute;
			right: 5px;
			bottom: 5px;
			z-index: 3;
		}
		.orders-state {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: none;
			.txt {
				position: absolute;
				top: 5px;
				right: 15px;
			}
			.orders-detail {
				&::after {
					display: none;
				}
				a {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					text-indent: -9999px;
					overflow: hidden;
				}
			}
		}
	}
}

// 个人资讯页
.profile {
	@media (max-width: $tablet-max) {
		.profile__info > div {
			flex-wrap: wrap;
		}
	}
	@media (max-width: $mobile) {
		.profile__content {
			flex-wrap: wrap;
			flex-direction: column-reverse;
			.profile__upload {
				margin-left: 0;
				margin-bottom: 15px;
				text-align: center;
			}
			.profile__upload-tips {
				max-width: unset;
			}
			.profile-label {
				width: 100%;
				margin-bottom: 5px;
			}
			.el-form-item {
				flex: 1;
			}
		}
	}
}

// 我的课程
.courses {
	@media (max-width: $tablet-max) {
		.course-info {
			.course-img {
				width: 25%;
			}
		}
	}
	@media (max-width: $mobile) {
		.course-info {
			.course-img {
				width: 30%;
			}
		}
		.el-card {
			position: relative;
		}
		.course-btn {
			padding: 6px 12px;
			margin-top: 5px;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
		.course_progress-left {
			font-size: 12px;
		}
	}
}

// 课程播放页
.courseDetail {
	@media (max-width: $tablet-max) {
		#course_vimeo {
			// margin: -15px 0 -15px -15px;
		}
		div.course_content {
			display: block;
			.course_content-left {
				width: 100%;
			}
			.course_content-right {
				position: static;
				width: 100%;
				margin-left: 0;
				overflow: visible;
			}
			.chapter-list {
				white-space: nowrap;
				overflow: auto;
				// margin-left: -15px;
				// margin-right: -15px;
			}
			.chapter_chpd {
				display: inline-block;
				width: 30%;
				margin-left: 15px;
				.chpd-cont {
					margin-left: 0;
					display: flex;
					flex-direction: column-reverse;
				}
				.chpd-img {
					width: 100%;
				}
			}
		}
		.course-info {
			padding-right: 90px;
			.el-button {
				display: block;
			}
		}
		.course-detail-item__content {
			flex-flow: column;
			.course-detail-item__content-left {
				flex-basis: 100%;
				margin-bottom: 10px;
			}
		}
		.course-detail-item__content-right {
			.attach {
				.el-button {
					display: none;
				}
				li {
					flex-basis: 100%;
				}
				a {
					align-items: flex-start;
					color: #409eff;
				}
			}
		}

		.course_title-top {
			display: flex;
			flex-flow: column;
		}
	}
}

// 商品列表
.hv-goods {
	@media (max-width: $tablet-max) {
		.hv-goods-item {
			&.el-col {
				&:not(.el-col-24) {
					width: calc(50% - 10px - 10px);
				}
				@media (min-width: $mobile + 1) {
					&.el-col-3 {
						width: calc(25% - 10px - 10px);
					}
				}
			}
		}
	}
}

.content {
	width: 1000px;
	margin: 0 auto;
	font-size: 16px;
	padding-top: 10px;
	@media (max-width: $tablet-max) {
		width: auto;
		padding: 10px 15px;
	}
}

.el-message-box {
	@media (max-width: $tablet-max) {
		width: 100% !important;
	}
}

.moBrowser {
	.commentDrawer,
	.detailDrawer {
		.el-dialog {
			margin: 0 !important;
			width: 100% !important;
			height: 100%;
		}
		.comment-form {
			// bottom: 30px;
			// display: flex;
			// // position: sticky;
			// width: calc(100% - 40px);
		}
	}
	.comment-list > .comment-item:last-of-type {
		padding-bottom: 100px;
	}
}
