@import "@/assets/scss/var.scss";






















































































































//第三種方案
.hv-goods {
	// margin-left: -10px;
	// margin-right: -10px;
	display: flex;
	flex-wrap: wrap;
	&-title,
	&-more {
		flex-basis: 100%;
		padding: 10px;
	}
	&-item {
		// width: calc(20% - 10px - 10px);
		transition: 0.2s;
		margin: $offset-base;
		cursor: pointer;
		// padding-bottom: 120px;
		box-sizing: border-box;
		position: relative;
		// border-radius: $radius;
		border: 1px solid #ebeef5;
		.el-card {
			border-radius: 0;
			border: none;
			box-shadow: none;
			height: 100%;
		}
        .image-box{
            position: relative;
            .stat{
                position:absolute;
                bottom: 0px;
                right: -2px;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 3px 8px;
                color: #fff;
            }
        }
		&-img {
			// height: 400px;
			// vertical-align: top;
			// width: 100%;
			// max-width: 100%;
			/deep/ .el-image {
				vertical-align: top;
				padding-top: 100%;
				position: relative;
				display: block;
                height: auto;
				img {
					// width: 100%;
					// height: 100%;
					// // object-fit: cover;
					// position: absolute;
					// top: 0;
					// left: 0;
					// border-radius: 0;
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 0;
				}
			}
		}
		&__info {
			padding: $offset-base;
			box-sizing: border-box;
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			flex: 1;
			// height: calc(100% - 400px);
			// position: absolute;
			// bottom: 0;
			// left: 0;
			// right: 0;
			// border: 1px solid #ebeef5;
			// border-top: 0;
			&-title {
				// height: 44px;
			}
			&-tags + &-tags {
				margin-left: 5px;
			}
			&-desc {
				color: $txt-color-grey;
				font-size: $fontsize-sm;
				@extend .ellipsis;
			}
			> div {
				margin-top: $offset-v;
			}
			> div:first-child {
				margin-top: 0;
			}
		}
		&__tag::after {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background: #fff;
			content: "";
		}
		&__tag {
			position: absolute;
			top: 0;
			left: 0;
		}
		&__tag.normal {
			display: inline-flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			align-items: center;
			color: #fff;
			font-size: 12px;
			top: 5px;
			left: 0px;

			.el-tag {
				padding: 3px 8px;
				line-height: inherit;
				border-radius: 0 999px 999px 0;
			}
		}
		&__tag.vertical {
			display: inline-flex;
			align-items: center;
			top: 0px;
			left: 5px;
			color: #fff;
			font-size: 12px;
			box-sizing: border-box;

			justify-content: center;
			text-align: center;
			.el-tag {
				padding: 3px 2px;
				color: #fff;
				border-radius: 0 0 999px 999px;
				word-break: break-all;
				width: 24px;
				min-height: 30px;
				height: auto;
				white-space: normal;
				line-height: normal;
			}
		}
		&__tag.custom {
			position: static;
			.el-image {
				position: absolute;
				top: 0;
				left: 0;
				max-width: 36px;
				max-height: 36px;
			}
		}
		&__bottom {
			// display: flex;
			// align-items: center;
			// justify-content: space-between;
			// flex-wrap: wrap;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-flow: wrap;
			> div {
				margin-bottom: $offset-v;
			}
			> .l {
				margin-right: $offset-h;
			}
			// flex-flow: column;
			&-oprice {
				font-size: $fontsize-sm;
				color: #999;
				text-decoration: line-through;
				margin-right: $offset-h;
			}
			&-price {
				font-size: $fontsize;
				color: red;
				flex: 1;
			}
			&-button {
				padding: 0;
				float: right;
			}
		}
	}
	/deep/ .el-card__body {
		padding: 0;
		flex-flow: column;
		height: 100%;
		display: flex;
		position: relative;
	}
	&-item:hover {
		box-shadow: 0 2px 12px 0 #ebebeb;
	}
	.el-col-24 {
		width: calc(100% - 10px - 10px);
	}
	.el-col-12 {
		width: calc(50% - 10px - 10px);
	}
	.el-col-8 {
		width: calc(33.33% - 10px - 10px);
	}
	.el-col-6 {
		width: calc(25% - 10px - 10px);
	}
	.el-col-5 {
		width: calc(20% - 10px - 10px);
	}
	.el-col-4 {
		width: calc(16.66667% - 10px - 10px);
	}
	.el-col-7 {
		width: calc(14.285% - 10px - 10px);
	}
	.el-col-3 {
		width: calc(12.5% - 10px - 10px);
	}
}
