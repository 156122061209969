@import "@/assets/scss/var.scss";


































































































.Coupon-item.boder {
    color: #ef0f25;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
}
