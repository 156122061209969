@import "@/assets/scss/var.scss";



































.hv-video {
	width: 100%;
	// height: 300px;
	// height: auto;
	background: #000;
}
