@import "@/assets/scss/var.scss";
.AddressWin {
    ul, li {
        list-style: none;
        padding: 0; margin: 0;
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #00c58f;
    }
    .el-radio__label {
        width: 100%;
        display: inline-block;
    }
    .el-radio__inner:hover {
        border-color: $border-primary;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: $border-primary;
        background: $btn-color-primary;
    }
    .el-radio.is-bordered.is-checked {
        border-color: $border-primary;
    }
    .el-radio__input.is-checked + .el-radio__label {
        color: $color-primary;
    }
    .title {
        font-size: 1.5em;
    }
    .el-dialog__body {
        padding-top: 10px;
        font-size: 16px;
    }
    .el-cascader {
        width: 100%;
    }
    .el-cascader .el-input .el-input__inner:focus,
    .el-cascader .el-input.is-focus .el-input__inner {
        border-color: $border-primary;
    }
    .el-checkbox {
        display: flex;
        align-items: center;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: $color-primary;
            border-color: $border-primary;
        }
        .el-checkbox__inner:hover {
            border-color: $border-primary;
        }
        .el-checkbox__inner:hover {
            border-color: $border-primary;
        }
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: $color-primary;
        }
    }
    ol,
    ul,
    li {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        /* font:inherit; */
        font-weight: normal;
        vertical-align: baseline;
        list-style: none;
    }
    .center {
        // padding: 0 1em;
        > ul {
            li {
                .label {
                    margin-bottom: 10px;
                }
            }
            li + li {
                margin-top: 20px;
            }
        }
        .l1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > span:first-child {
                width: 260px;
            }
        }
        .btn {
            text-align: center;
            margin-top: 30px;
            .el-button {
                min-width: 80px;
            }
        }
    }

}
