@import "@/assets/scss/var.scss";











































































































































































.el-dropdown-link {
	/deep/ .el-image {
		width: 25px;
		height: 25px;
		margin-right: 5px;
		border-radius: 20px;
	}
}
.el-menu-item:empty {
	padding: 0;
}
.userInfo {
	display: none;
}
.showUserInfo {
	display: list-item;
}
.el-menu-item {
	/deep/.el-badge.mark {
		margin-top: -4px;
		margin-left: 3px;
		.el-badge__content {
			height: auto;
			line-height: 1.5;
			border: none;
		}
	}
}
.el-image.icon {
	// width: 30px;
	height: 30px;
    margin-right: 5px;
}
