@import "@/assets/scss/var.scss";

















































































.hv-title{
    display: block;
    padding: 15px;
    background: #fff;
    word-break: break-all;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    p{
        margin: 0;
    }
    &__content{
        display: flex;
        align-items: baseline;
    }
    &__title{
        font-size: 16px;
        font-weight: bold;
    }
    &__desc{
        font-size: 12px;
        color: $txt-color-grey;
        margin-left: 5px;
    }
    &__more{
        color: $txt-color-grey;
        display: flex;
        align-items: center;
        font-size: 12px;/*no*/
        white-space: nowrap;
        .van-icon-arrow{
            line-height: 0;
        }
    }
}
.hv-title.wrap{
    .hv-title{
        &__content{
            flex-flow: wrap !important;
        }
    }
}
.hv-title.column{
    .hv-title{
        &__content{
            flex-flow: column !important;
        }
        &__desc{
            margin-left: 0;
        }
    }
}
.hv-title-align{
    text-align: center;
    flex-flow: column;
    align-items: center;
    >div{
        // width: 100%;
        margin-bottom: 5px;
    }
    .hv-title{
        &__content{
            flex-flow: column;
        }
    }
}
.hv-title.center{
    @extend .hv-title-align;
    align-items: center;
    .hv-title{
        &__content{
            align-items: center;
        }
    }
}
.hv-title.left{
    @extend .hv-title-align;
    align-items: baseline;
    flex-flow: row;
}
.hv-title.right{
    @extend .hv-title-align;
    align-items: flex-end;
    .hv-title{
        &__content{
            align-items: flex-end;
        }
    }
}
