@import "@/assets/scss/var.scss";



























































































































































































































































































































































































































































































































































































































































































































































.hv-form {
	//  /deep/.van-cell.van-field--error{
	//     .van-cell__value.van-field__value{
	//         border: 1px solid #ef0f25;
	//     }
	// }
	/deep/ input {
		background-color: transparent !important;
	}
	/deep/ textarea {
		background-color: transparent !important;
	}
	.empty {
		height: 80px;
	}
	&-footer {
		padding: 15px;
		> .van-button {
			width: 100%;
		}
	}
	&-banner {
		height: 200px;
		position: relative;
		.van-image {
			height: 100%;
			width: 100%;
		}
	}
	&__body {
		position: relative;
		background: #fff;
		margin: 15px;

		border-radius: 10px;
	}
	&-top {
		padding: 15px;
		> div {
			margin-bottom: 5px;
		}
		> div:last-of-type {
			margin-bottom: 0;
		}
	}
	&-banner + &__body {
		margin-top: -30px;
	}
	&-title {
		font-size: 20px;
		font-weight: bold;
	}
	&-desc {
		font-size: 14px;
		color: #9a9e9f;
	}
	/deep/ .van-field {
		// margin-bottom: $offset-v-lg;
		display: flex;
		flex-flow: column;
		> div {
			margin-bottom: 5px;
		}
		// >div:last-of-type{
		//     margin-bottom: 0;
		// }
		&__label {
			width: auto;
			margin-right: 0;
			color: #333;
			font-weight: bold;
		}
		&__value {
			order: 2;
		}
		.field-extra {
			margin-top: 0;
			order: 1;
		}

		&__control {
			flex-wrap: wrap;

			.van-checkbox-group,
			.van-radio-group {
				margin-top: $offset-v;
				margin-bottom: $offset-v;

				&--horizontal {
					width: 50%;
				}
			}
		}
		.must {
			color: red;
		}
	}
	/deep/ .radio-images,
	.checkbox-images {
		padding-top: 0;
		padding-bottom: 0;
		.van-radio-group,
		.van-checkbox-group {
			display: flex;
			width: 100%;
			flex-flow: wrap;
			margin: 0;
			align-items: baseline;
			.radio-item,
			.checkbox-item {
				// flex: 1;
				flex-basis: 50%;
				display: flex;
				padding: 10px;
				box-sizing: border-box;
				padding-top: 0;
				padding-left: 0;
				&__body {
					border: 1px solid #ccc;
					width: 100%;
					height: 100%;
					display: flex;
					flex-flow: column;
					align-items: center;
					box-sizing: border-box;
					padding: 10px;
					.van-image {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
	/deep/ .van-field.is-border {
		.van-field__value {
			border: 1px solid #ccc;
			padding: 5px 10px;
		}
	}
	/deep/ .van-field.icon-field {
		.van-field__body {
			> input {
				order: 1;
			}
			.van-field__right-icon {
				margin-right: 0;
				margin-left: -10px;
				.van-icon {
					font-size: 20px;
				}
			}
		}
	}

	/deep/ .van-field.is-choose-border {
		.van-field__value {
			border: 1px solid #ccc;
		}
		.van-field__body {
			.van-checkbox-group,
			.van-radio-group {
				margin: 0;
				width: 100%;
				.van-checkbox,
				.van-radio {
					border-bottom: 1px solid #ccc;
					width: 100%;
					padding: 10px;
					box-sizing: border-box;
				}
				.van-checkbox:last-of-type,
				.van-radio:last-of-type {
					border-bottom: none;
				}
			}
		}
	}

	>>> input,
	>>> textarea {
		background: #f7f8fa;
		padding: $offset-v $offset-h;
		border-radius: $radius;
	}

	.pickerArrow {
		>>> .van-field__body {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: $offset-h;
				transform: translateY(-25%);
				display: block;
				border-width: 5px;
				border-style: solid;
				border-color: $txt-color-grey transparent transparent transparent;
			}

			input {
				padding-right: 30px;
			}
		}
	}
}
.hv-form.block {
	/deep/ .van-form {
		.hv-form__body {
			background: inherit;
			margin: 15px;
		}
		.hv-form-top {
			margin-bottom: 10px;
			background: #fff;
			border-radius: 5px;
			border: 1px solid #f1f1f1;
		}
		.hv-form-center {
			> div {
				margin-bottom: 10px;
				border: 1px solid #f1f1f1;
				.van-cell {
					border-radius: 5px;
				}
			}
		}
		.van-field.is-border {
			.van-field__value {
				border: none;
				border-bottom: 1px solid #ccc;
				padding: 5px 10px;
			}
		}
		.radio-images,
		.checkbox-images {
			padding-top: 10px;
			padding-bottom: 10px;
			.van-radio-group,
			.van-checkbox-group {
				.radio-item,
				.checkbox-item {
					&__body {
						border: none;
						padding: 0;
					}
				}
			}
		}
		.van-field.is-choose-border {
			.van-field__value {
				border: none;
			}
			.van-field__body {
				.van-checkbox-group,
				.van-radio-group {
					.van-checkbox,
					.van-radio {
						border-bottom: none;
					}
				}
			}
		}
	}
	.hv-form-footer {
		padding: 0;
		// position: fixed;
		// bottom: 0;
		// left: 0;
		// right: 0;
		// padding: 0;
		// background: #fff;
	}
}
