@import "@/assets/scss/var.scss";
.CouponGet  {
    .el-dialog__body {
        padding-top: 0;
        padding-bottom: 0
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-dialog {
        background: #DD381C;
    }
    .el-dialog__footer {
        text-align: center;
    }
    .center{
        max-height: 500px;
        overflow-y: auto;
        .Coupon-item{
            background: #fff;
            .l{
                width: unset;
                flex: 1;
            }
        }
        .Coupon-item + .Coupon-item{
            margin-top: 10px;
        }
    }
}  