@import "@/assets/scss/var.scss";
html {
    height: 100%;
}
html,
body {
    min-height: 100%;
    background: #f7f8fa;
}
body {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 0;
    
}
#app {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}
// .content {
//     width: 1000px;
//     min-width: 960px;
//     margin: 0 auto;
//     font-size: 16px;
//     padding-top: 10px;
// }
.page-content {
    box-sizing: border-box;
}
.mxde{
    max-width: 1000px;
}
.mx100p{
    width: 100%;
    margin: 0 auto;
}

// page-content 置中容器
.page-content {
    // @media (min-width: $desktop) {
        max-width: $com-width;
        margin: 0 auto;
    // }
    padding-left: $com-offset;
    padding-right: $com-offset;
    // min-width: $com-min-width;
    // font-size: $fontsize;
    // padding-top: $offset-v;
}
.page-container{
    width: 1000px;
    padding: 0;
    margin: 0 auto;
}
.main {
    height: auto;
    flex: auto;
    background: #f7f8fa;
}
.main > div {
    padding-top: 15px;  //在main处替换page-content的padding-top
}
// .main-inner {
//     flex: 1;
//     overflow-x: hidden;
//     overflow-y: auto;
// }
// .content {
//     width: $com-width;
//     min-width: $com-min-width;
//     margin: 0 auto;
//     font-size: $fontsize;
//     padding-top: $offset-v;
// }
.el-dialog {
    .content {
        width: auto;
        min-width: unset;
    }
}
// .el {
//     &-form {
//         .el-form-item {
//             margin-bottom: 20px;
//         }
//     }
// }
img {
    // width: 100%;
    // display: block;
    // height: 100%;
    // object-fit: cover;
    max-width: 100%;
}
.noImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #dcdee0;
}
.noHeaderImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #f5f7fa;
    color: #dcdee0;
    border-radius: 100%;
    border: 1px solid #dcdee0;
    .image-slot {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
    }
    .el-image__inner {
        object-fit: cover;
    }
}
.s_icon {
    width: 30px;
    height: 30px;
    vertical-align: -8px;
    fill: currentColor;
    overflow: hidden;
}
.hv-goods {
    margin-left: -10px;
    margin-right: -10px;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.32);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
::-webkit-scrollbar-track {
    background: rgb(245, 245, 245);
}
a {
    color: inherit;
    text-decoration: none;
}
.vhtml {
    img {
        max-width: 100%;
        // height: auto !important;
    }
}

body {
    font-family: "PMingLiU", "Microsoft JhengHei", "Microsoft YaHei", Tohoma, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.goods-widget {
    position: fixed;
    bottom: 30%;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 2;
    box-shadow: 3px 2px 9px#e6e6e6;
    .widget-item {
        display: flex;
        text-align: center;
        padding: 6px;
        width: 48px;
        height: 48px;
        background: #fff;
        flex-flow: column;
        cursor: pointer;
        border-bottom: 1px dashed #e6e6e6;
        position: relative;
    }
    .widget-item:last-child {
        border-bottom: none;
    }
    .widget-item-mark {
        position: absolute;
        right: 9px;
        top: 0;
        .el-badge__content {
            border: none;
            height: auto;
            line-height: 1.5;
        }
    }
    .hv-ico {
        margin: 5px;
        width: auto;
        height: auto;
        > i {
            font-size: 22px;
        }
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon {
        width: 1.2em;
        height: 1.2em;
    }
}
.hv-header {
    border-bottom: solid 1px #e6e6e6;
    // position: sticky;
    top: 0;
    // z-index: 3;
    background: #fff;
    .page-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-logo {
        cursor: pointer;
        max-height: 60px; // 避免超出商品详情页的tab的sticky效果
        // height: 30px;
        /deep/ img {
            width: auto;
            height: 100%;
        }
    }

    .el-menu.el-menu--horizontal {
        border: none;
    }
    .el-submenu__icon-arrow {
        vertical-align: text-bottom !important;
    }
}
.hv-footer .vhtml:not(:empty) {
    margin: $offset-v 0;
}
