@import "@/assets/scss/var.scss";










































































.el-menu {
  > li {
    // width: 120px;
    // text-align: center;
    // height: 50px;
    // line-height: 50px;
    flex: 1;
    text-align: center;
    /deep/ .el-submenu__title {
      // height: 50px;
      // line-height: 50px;
    }
  }
}
.el-menu-item {
  padding: 0 !important;
  > a {
    height: 100%;
    width: 100%;
    display: inline-block;
  }
  /deep/.el-badge.mark {
    margin-top: -4px;
    margin-left: 3px;
    .el-badge__content {
      height: auto;
      line-height: 1.5;
      border: none;
    }
  }
}
.el-image.icon {
  // width: 30px;
  height: 30px;
  margin-right: 5px;
}
