@import "@/assets/scss/var.scss";
.InvoiceWin {
    .el-select{
        width: 100%;
        .el-input__inner:focus {
            border-color: transparent !important;
        }
        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
            background-color: #fff;
        }
        .el-select-dropdown__item.selected {
            color: $color-primary;
            font-weight: 700;
        }
    }
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: $border-primary;
    }
    .el-radio__label {
        width: 100%;
        display: inline-block;
    }
    .el-radio__inner:hover {
        border-color: $border-primary;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: $border-primary;
        background: $btn-color-primary;
    }
    .el-radio.is-bordered.is-checked {
        border-color: $border-primary;
    }
    .el-radio__input.is-checked + .el-radio__label {
        color: $color-primary;
    }
    .title {
        font-size: 1.5em;
    }
    .el-dialog__body {
        padding-top: 10px;
        font-size: 16px;
    }
    .el-cascader {
        width: 100%;
    }
    .el-cascader .el-input .el-input__inner:focus,
    .el-cascader .el-input.is-focus .el-input__inner {
        border-color: $border-primary;
    }
    .el-checkbox {
        display: flex;
        align-items: center;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color:$color-primary;
            border-color: $border-primary;
        }
        .el-checkbox__inner:hover {
            border-color: $border-primary;
        }
        .el-checkbox__inner:hover {
            border-color: $border-primary;
        }
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: $color-primary;
        }
    }
    .el-select .el-input {
        width: 40px;
    }
    .el-input-group__append, .el-input-group__prepend {
        background-color: #fff;
    }
    .ct {
        // padding: 0 1em;
        >ul {
            >li {
                margin-bottom: 10px;
                .label {
                    margin-bottom: 10px;
                }
            }
        }
        .l1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > span:first-child {
                width: 260px;
            }
        }
        .l6 {
            text-align: center;
            margin-top: 30px;
            > span {
                padding: 10px 38px;
                display: inline-block;
                text-align: center;
                font-size: 14px;
                line-height: 1.5;
                border: 1px solid transparent;
                background: transparent;
                cursor: pointer;
                background: $btn-color-primary;
                color: #fff;
                width: auto;
                height: 100%;
            }
            > span:first-child {
                margin-right: 30px;
                color: $color-primary;
                border-color: $border-primary;
                background: #fff;
            }
        }
    }
    ul, li {
        margin: 0; padding: 0;
        list-style: none;
    }
}
