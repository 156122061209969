@import "@/assets/scss/var.scss";
.sw-check-out-list {
    flex: 1;
    display: flex;
    border: 2px dashed #dcdfe6;
    flex-flow: column;
    justify-content: space-between;
    margin-left: $offset-h-lg;
    min-height: 120px;
    > ul {
        padding: $offset-base-lg;
        li {
            display: flex;
            justify-content: space-between;
        }
        li + li {
            margin-top: $offset-v;
        }
    }
    &-total-price {
        color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $offset-base-lg;
        padding-top: 0;
        &-num {
            font-size: 26px;
        }
    }
}
