@import "@/assets/scss/var.scss";







































































.other-login {
    // padding: 0 16px;
    .el-divider {
        border-color: #999;
    }
}
.fast-login {
    display: flex;
    flex-flow: column;
    .el-button /deep/ {
        height: auto;
        padding: 0;
        border: none;
        border-radius: 4px;
        margin-bottom: $offset-v-lg;
        width: 100%;
        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        .txt {
            color: #fff;
        }
        .btn-login-icon {
            display: inline-block;
            margin: 2px;
        }
        .icon {
            width: 35px;
            height: 35px;
            cursor: pointer;
        }
    }

    .el-button + .el-button {
        margin-left: 0;
    }
}
.btn-login {
    &-fb:hover,
    &-fb:focus {
        color: #1877f2;
        border-color: #1877f2;
        background-color: #1877f2;
    }
    &-fb {
        background: #1877f2;
    }
    &-line:hover,
    &-line:focus {
        color: #00b800;
        border-color: #00b800;
        background-color: #00b800;
    }
    &-line {
        background: #00b800;
        .btn-login-icon {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 5px;
                right: 5px;
                bottom: 5px;
                left: 5px;
                background: #fff;
                border-radius: 100%;
            }
            .icon {
                position: relative;
                z-index: 1;
            }
        }
    }
}
