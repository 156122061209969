@import "@/assets/scss/var.scss";
.IntroDialog {
    background: #fff;

    .el-dialog__header {
        padding-top: 0;
    }
    .el-dialog__body {
        // padding-top: 0;
        // padding-bottom: 0
    }
    .el-dialog__title {
        color: #fff;
    }
    .intro {
        &_center {
            // display: flex;
            // flex-flow: column;
            // align-items: center;
            // padding: $offset-v-md $offset-h-md;
            overflow: auto;
            > div {
                // margin-bottom: 10px;
            }
        }
        &_avatar {
            width: 100px;
            height: 100px;
            float: left;
            margin-right: $offset-h-md;
        }
        &_name {
            font-size: 18px;
            font-weight: bold;
            color: $color-primary;
        }
        &_desc {
          overflow: auto;
        }

        @media (max-width: $mobile) {
          &_avatar {
            width: 50px; height: 50px;
          }
          &_name {
            margin-top: 15px;
            overflow: auto;
          }
          &_desc {
            clear: both;
          }
        }
        // &_footer {
        //     .title {
        //         font-size: 18px;
        //         font-weight: bold;
        //         color: $color-primary;
        //     }
        // }
        &_courses {
            overflow: auto;
            display: flex;
            flex-wrap: wrap;
            margin-left: -8px;
            margin-right: -8px;
        }
    }
    .course {
        &_item {
            width: calc(25% - 8px - 8px);
            @media (max-width: $tablet-max) {
                 width: calc(50% - 8px - 8px);       
            }
            padding-right: 8px;
            padding-left: 8px;
            cursor: pointer;
        }
        &_img {
            .noImg {
              @include thumb-square();
                // max-height: 250px;
                // height: 150px;
                img {
                    object-fit: cover;
                }
            }
            // width: 160px;
        }
        &_title {
            margin-top: 5px;
        }
        &_item + &_item {
            margin-left: 10px;
        }
    }
}
