@import "@/assets/scss/var.scss";
































































.hv-image {
    position: relative;
    height: 100%;
    max-width: 100%;
    // min-height: 250px;
    &-tip {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        min-height: 20px;
        padding: 0;
        color: #fff;
        font-size: 14px;
        line-height: 1.5em;
        /* text-align: center; */
        padding: 10px;
        display: flex;
        align-items: center;
        background-color: rgba(51, 51, 51, 0.8);
    }
    .el-image.is-pointer {
        cursor: pointer;
    }
    .el-image.is-video::before {
        content: "";
        display: block;
        width: 60px;
        height: 40px;
        background: rgba(0, 0, 0, 0.75);
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
    }
    .el-image.is-video::after {
        content: "";
        display: block;
        border-style: solid;
        border-width: 10px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-15%, -50%);
    }
}
.el-image {
    vertical-align: top;
    height: 100%;
    width: 100%;
}
