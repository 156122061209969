@import "@/assets/scss/var.scss";






































































.hv-header {
	border-bottom: solid 1px #e6e6e6;
	// position: sticky;
	top: 0;
	// z-index: 3;
	background: #fff;
	> .content {
		display: flex;
		// align-items: center;
		align-items: flex-start;
		justify-content: space-between;
		flex-flow: wrap;
	}
	.content-right {
		display: flex;
		flex-flow: column;
		align-items: flex-end;
	}
	&-logo {
		cursor: pointer;
		// height: 30px;
		/deep/ img {
			width: auto;
			height: 100%;
		}
	}

	/deep/ .el-menu.el-menu--horizontal {
		border: none;
	}
	/deep/ .el-submenu__icon-arrow {
		vertical-align: text-bottom !important;
	}
}
