@import "@/assets/scss/var.scss";
















.null {
    color: $txt-color-grey;
    padding: $offset-v-lg $offset-h-lg;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    .icon {
        width: 100px;
        height: 100px;
    }
}
