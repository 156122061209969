@import "@/assets/scss/var.scss";






































.hv-tabs {
	/deep/ .el-tabs {
		.el-tabs__nav-wrap {
			padding: 10px;
		}
	}
}
