@import "@/assets/scss/var.scss";

































.hv-notice {
  min-height: 40px !important;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
