@import "@/assets/scss/var.scss";
.Coupon {
    .inbox {
        width: 48%;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .is-boder {
        display: inline-flex;
        border: 1px solid #ef0f25;
        border-radius: 4px;
        cursor: pointer;
    }
    /deep/ .el-tabs__content {
        .el-tab-pane {
            display: flex;
            flex-flow: wrap;
        }
    }
    &-item {
        display: flex;
        align-items: center;
        padding: 18px 20px 18px 20px;
        width: 100%;
        box-sizing: border-box;
        .l {
            white-space: normal;
            word-break: break-all;
            width: 250px;
            > div {
                display: flex;
                flex-flow: column;
                margin-right: 10px;
                .label {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                em {
                    font-size: 12px;
                    color: #c0c4cc;
                }
            }
            > .desc {
                color: #c0c4cc;
                margin-top: 10px;
            }
        }
        .r {
            color: #dd381c;
            .num {
                font-size: 1.75em;
                font-style: italic;
                vertical-align: middle;
                margin-bottom: 5px;
                // display: inline-block;
                display: flex;
                align-items: center;
                white-space: nowrap;
                small {
                    opacity: 0.5;
                }
            }
            .unti {
                display: inline-flex;
                vertical-align: middle;
                flex-direction: column;
                font-size: 12px;
                margin-left: 5px;
            }
            > div {
                .img-box {
                    position: relative;
                    .image-slot {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background: #f5f7fa;
                        color: #909399;
                        font-size: 20px;
                    }
                }
                .el-tag {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 0 7px;
                    height: 25px;
                    line-height: 25px;
                    color: #fff;
                    background: #dd381c;
                    border: none;
                }
            }
        }
    }
    .el-dialog__body {
        padding-top: 0;
    }
    .el-tabs__item.is-active {
        color: $color-primary;
    }
    .el-tabs__active-bar {
        background-color: $color-primary;
    }
    .el-tabs__item:hover {
        color: $color-primary;
        cursor: pointer;
    }
    .el-checkbox__label {
        padding-left: 0px;
    }
    .el-checkbox {
        margin-right: 10px;
    }
    .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }
    .el-checkbox.is-bordered {
        // height: 55px;
        width: 100%;
        height: 100%;
    }
    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
        margin-top: 10px;
    }
    .el-checkbox__label .el-image {
        margin-right: 5px;
    }
    .el-checkbox.is-bordered.el-checkbox {
        padding: 0;
    }
    .el-checkbox__label {
        display: inline-flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: space-between;
    }
    .el-checkbox.is-bordered.is-checked {
        border-color: $border-primary;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: $color-primary;
    }
    .el-checkbox.is-bordered.el-checkbox .el-checkbox__inner {
        opacity: 0;
        outline: none;
        position: absolute;
        margin: 0;
        z-index: -1;
        height: 0px;
        width: 0px;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label::before {
        content: "";
        display: block;
        width: 8px;
        height: 4px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        transform: rotate(-45deg);
        position: absolute;
        right: 2px;
        bottom: 5px;
        z-index: 1;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-bottom: 20px solid $border-primary;
        border-left: 25px solid transparent;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .notfound {
        text-align: center;
        .icon {
            width: 6em;
            height: 6em;
        }
    }
}
.Coupon-item.coupon-used,
.Coupon-item.coupon-invalid {
    background-image: url(~@/assets/images/used.png);
    background-position: 96% center;
    background-repeat: no-repeat;
    background-size: 80px 80px;

    .r {
        color: #ccc;
    }
}

.Coupon-item.coupon-invalid {
    background-image: url(~@/assets/images/Expired.png);
}
