@import "@/assets/scss/var.scss";

































































// .hv-grid:after {
//     content: "";
//     display: block;
//     visibility: hidden;
//     clear: both;
// }
.hv-grid {
    font-size: 14px;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .grid {
        &-item {
        }
        &-content {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            /deep/.hv-image {
                max-height: 100px;
                max-width: 100px;
            }
        }
    }
}
