<template>
    <div class="shop-info">
        <div class="logo">
            <img :src="config.logo" v-if="config.logo" />
            <h1 v-else>{{config.storeName}}</h1>
        </div>
        <div class="slogan" v-if="config.slogan">
            {{ config.slogan }}
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    computed: {
        ...mapState({
            config(state) {
                return state.base.configInfo;
            },
        })
    }
}
</script>

<style>
.shop-info {
  text-align: center;
}
.hv-header ~ .login-form .logo {
  display: none;
}
</style>
