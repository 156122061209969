<template>
    <div class="banner banner-login">
        <div class="banner-logo">
            <!-- <div class="lg-sys-box">
                
                <div class="lg-sys-name">{{ text }}</div>
            </div> -->
            <div class="container">
                <div class="banner-form">
                    <div class="logo">
                        <img :src="config.logo" />
                    </div>
                    <div class="banner-slogan" v-if="config.slogan">
                        {{ config.slogan }}
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    components: {},
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
            chkAcctExistStat() {
                return this.$store.state.base.chkAcctExistStat;
            },
        }),
    },
    props: {
        text: String,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.banner {
    background: #f7f8fa;
    overflow: auto;
    flex: auto;

    &-logo {
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 15%;
        right: 15%;
        display: flex;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
        img {
            width: auto;
            display: unset;
        }
    }
    &-slogan {
        // color: #fff;
        padding: 6px 30px;
        text-align: center;
        // font-size: 21px;
        position: relative;
        top: 15%;
    }
    &-logo {
        // left: 50%;
        // width: $com-width;
        // right: auto;
        // transform: translateX(-50%);
        // background-image: url(~@/assets/images/bg2.jpg);
        @media (max-width: 599px) {
            position: static;
        }
        > * {
            margin: 0;
            flex: 1;
            padding: 0;
            padding-top: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
            padding-left: 0px;
        }
        .lg-sys-box {
            position: relative;
            .logo {
                position: relative;
                top: 25px;
                left: 25px;
                color: #fff;
                font-size: 40px;
                text-transform: uppercase;
                font-weight: normal;
                display: inline-block;
                padding-top: 6px;
                padding-bottom: 6px;
                width: 150px;
                img {
                    // height: 40px;
                    // filter: contrast(0) brightness(10);
                    // margin-bottom: -5px;
                    // vertical-align: baseline;
                }
            }
            .lg-sys-name {
                position: absolute;
                right: 30px;
                bottom: 50px;
                color: #fff;
                font-size: 30px;
                letter-spacing: 4px;
                text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
            }
        }
        .container {
            height: 100%;
            overflow: auto;
            @media (min-height: 600px) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
            background-color: #fff;
            // box-shadow: -5px 0 5px rgba(0, 0, 0, 0.5);
            // position: relative;
            // overflow: hidden;
            padding-left: 12px;
            padding-right: 12px;
            .banner-form /deep/ {
                width: 360px;
                margin: auto;
                .el-dialog .content {
                    // max-width: 400px;
                }
                .el-dialog__header {
                    padding: 0;
                }
                .register-inner {
                    padding: 30px 20px; // el-dialog padding
                }
                // max-width: 600px;
                // @media (min-height: 600px) {
                //     width: 100%;
                // }
                // padding: 0 50px;
                // position: absolute;
                // height: 100%;
                // width: 100%;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // overflow: auto;
                .el-form-item__content {
                    margin-left: 0 !important;
                }
                .el-dialog__wrapper,
                > div {
                    position: static !important;
                    // position: absolute;
                    // width: 464px;
                    // top: 25%;
                    // left: 50%;
                    // // overflow: hidden;
                    // margin-left: -232px;
                    .el-dialog {
                        margin-top: 0 !important;
                        width: 100% !important;
                        box-shadow: none;
                        margin-bottom: 0;
                    }
                }
                // > div {
                //     padding: 0 20px;
                // }
                .el-dialog__wrapper {
                    padding: 0;
                }
            }
        }
    }
}
</style>
<style>
.hv-header ~ .register .logo ,
.hv-header ~ .banner-login .logo {
    display: none;
}
</style>
