@import "@/assets/scss/var.scss";
.payway {
    width: 100%;
    font-size: 16px;
    .title {
        width: 100%;
        margin-top: 10px;
        // font-size: 18px;
    }
    .option-btn {
        margin: 15px 0 15px 0;
        padding-bottom: 20px;
        // border-bottom: 1px solid #f1f1f1;
        .el-radio {
            position: relative;
            // min-width: 245px;
            height: 50px;
            line-height: 50px;
            margin-right: 0;
            width: calc(25% - 10px);
            margin-right: 5px;
            margin-left: 5px;
            margin-top: 10px;
        }
        .el-radio.is-bordered .el-radio__inner {
            opacity: 0;
            outline: none;
            position: absolute;
            margin: 0;
            z-index: -1;
            height: 0px;
            width: 0px;
        }
        .el-radio-group {
            display: flex;
            flex-flow: wrap;
            margin-left: -5px;
            margin-right: -5px;
        }
        .el-radio.is-bordered + .el-radio.is-bordered {
            margin-left: 5px;
        }
        .el-radio__label {
            padding-left: 0px;
        }
        .el-radio__label .el-image {
            margin-right: 5px;
            display: flex;
        }
        .el-radio.is-bordered {
            padding-top: 0px;
        }

        .el-radio__label {
            display: inline-flex;
            align-items: center;
            height: 100%;
        }
        .el-radio.is-bordered.is-checked {
            border-color: $border-primary;
        }
        .el-radio__input.is-checked + .el-radio__label {
            color: $color-primary;
        }
        .el-radio__input.is-checked + .el-radio__label::before {
            content: "";
            display: block;
            width: 8px;
            height: 4px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            right: 2px;
            bottom: 5px;
            z-index: 1;
        }
        .el-radio__input.is-checked + .el-radio__label::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-bottom: 20px solid $border-primary;
            border-left: 25px solid transparent;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .el-radio.is-bordered.is-disabled {
            cursor: not-allowed;
            border: 2px dashed;
            border-color: #ebeef5;
            /* border-style: dashed; */
        }
    }
    &-info {
        display: flex;
        align-items: center;
        img.el-image__inner {
            height: 25px;
            width: auto;
        }
    }
}
