$coupon-color:#e74c2c;
$coupon-color2:#fdf1ed;
$coupon-height:90;

.van-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.van-multi-ellipsis--l2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.seting{
    padding: 20px 0;
    .el-form{
        >.el-form-item{
            padding: 0 20px;
        }
        .children{
            padding: 10px;
        }
    }
}
.el-form-item{
    .el-radio-group{
        margin-top: 10px;
        .el-radio{
            margin-bottom: 10px;
        }
    }
}
.ytIframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.ytIframe iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}