@import "@/assets/scss/var.scss";
.comment {
	color: #2c3e50;

	&-form {
		// position: fixed;
		// bottom: 30px;
		// // left: 0;
		// // right: 0;
		// width: 32%;
		// display: flex;
		// z-index: 2;
		display: flex;
		margin-bottom: 20px;
	}
	&-list {
		margin: 0 20px;
		background-color: #fff;
		position: relative;

		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}
	}
	&-item {
		margin-bottom: 10px;
		.comment {
			display: flex;
		}
	}

	.content-box {
		margin-left: 10px;
		border-bottom: 1px solid #f1f1f1;
		flex: 1;
		.meta-box {
			display: flex;
			align-items: center;
			.more {
				cursor: pointer;
				margin-left: 10px;
				font-size: 13px;
				color: blue;
			}
			.time {
				margin-left: auto;
				color: #8a9aa9;
				font-size: 13px;
			}
		}
		.content-text {
			// margin-top: 10px;
			margin: 10px 0;
			line-height: 25px;
			white-space: pre-line;
			word-break: break-all;
			color: #505050;
			overflow: hidden;
		}
		.reply-stat {
			// margin-top: 10px;
			margin-bottom: 10px;
			display: flex;
			.return {
				padding: 3px 12px;
				background: #fafbfc;
				border-radius: 20px;
				cursor: pointer;
			}
		}
		.reply-form {
			margin-top: 10px;
			display: flex;
		}

		.sub-comment-list {
			margin: 10px 0;
			padding: 0 0 0 10px;
			background-color: #fafbfc;
			border-radius: 3px;
		}
		.sub-comment-list .comment-item {
			margin-bottom: 0;
		}
		.sub-comment-list .comment-item .comment {
			position: relative;
			padding: 10px 0 0;
		}
		.sub-comment-list .comment-item .comment .content-box {
			margin-right: 10px;
			padding-bottom: 10px;
		}
		.sub-comment-list .comment-item:last-child .content-box {
			border-bottom: none;
		}
	}
}
.hv-comment {
	overflow: auto;
	// max-height: 400px;
}
.loading,
.noMore {
	padding: 30px;
	padding-top: 0;
	text-align: center;
}
.noMore {
	color: #ccc;
}
.perch {
	height: 60px;
}

.comment-item:nth-last-child(2) .comment .content-box {
	border-bottom: none;
}
.comment-list > .comment-item:last-child {
	// padding-bottom: 200px;
}
.detail-comment-item:nth-last-child(2) .sub-comment-list .comment .content-box {
	border-bottom: 1px solid #f1f1f1;
}
.detail-top {
	display: flex;
	align-items: center;
	> p {
		margin-right: 10px;
	}
	.avatar {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		margin-right: 5px;
	}
}
.commentDrawer .el-drawer__body {
	overflow: hidden;
}
