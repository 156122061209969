@import "@/assets/scss/var.scss";














































































.pop-video {
    padding: 0;
    overflow: visible;
    width: 100%;
    .video-frame {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        background: #fff;
        iframe {
            width: 100% !important;
            height: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            // border: 10px solid #fff;
        }
    }

    /deep/ .van-popup__close-icon {
        position: absolute;
        left: 50%;
        bottom: -50px;
        top: auto;
        transform: translateX(-50%);
    }
}
