@import "@/assets/scss/var.scss";







































































































































































.popup-verify {
    // position: fixed;
    // overflow: hidden;
    // transform: translate(-50%, -50%) !important;
    // top: 30%;
    // left: 50%;
    // width: 60%;
    /deep/ .el-dialog {
        // margin-top: 0;
        // padding: 0;
        // padding-top: 100%;
        width: 30%;
        // border-radius: 0.32rem;
        // padding: 0.533333rem;
    }
    .sliding-pictures {
        position: relative;
        // left: 20px;
        // top: 20px;
        .vimg {
            #codeImg,
            #sliderBlock {
                width: inherit;
                height: inherit;
            }
            #sliderBlock {
                position: absolute;
                z-index: 4000;
            }
        }
        .slider {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .track {
                width: 100%;
                height: 35px;
                background: rgba(28, 136, 188, 0.5);
                border-radius: 25px;
                font-size: 14px;
                line-height: 35px;
                padding-right: 15px;
                padding-left: 70px;
            }
            .pintuTrue {
                background: #67c23a;
                color: #ffffff;
            }
            .button {
                margin-left: -7px;
                position: absolute;
                width: 45px;
                height: 45px;
                line-height: 43px;
                background: #ffffff;
                box-shadow: #b9bdc8 0 0 3px;
                border-radius: 50%;
                left: 7px;
                text-align: center;
                font-size: 28px;
                color: #3e5d8b;
                z-index: 9999;
                &:hover {
                    color: #2181bd;
                }
            }
        }
        .operation {
            width: 100%;
            height: 40px;
            text-align: center;
            > i {
                color: #9fa3ac;
                display: inline-block;
                width: 40px;
                font-size: 25px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
}
