@import "@/assets/scss/var.scss";

































































































































































































.hv-swiper {
	.my-swipe {
		// height: 350px;
		/deep/.el-carousel__container {
			position: relative;
			height: 100%;
		}
		.el-carousel__item {
			display: inline-flex;
			align-items: center;
		}
		.swiper-item {
			display: flex;
			// background: #000;
			width: 100%;
		}
	}
}
