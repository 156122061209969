@import "@/assets/scss/var.scss";






























































.nav-bar {
    // background: #545c64;
    // height: 50px;
}
.el-menu {
    display: flex;
    flex-flow: wrap;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
